<script  setup>
import router from "@/router";
import { ref,onMounted } from 'vue';
import * as echarts from 'echarts';
import axios from "axios";

const click = (event) => {
  const selectedValue = event.target.value;
  // 根据不同的选项值进行页面跳转逻辑
  switch (selectedValue) {
    case 'Usage':
      router.push({name:'Usageinformation'});
      break;
    case 'Toxicity':
      router.push({name:'toxicityinformation'});
      break;
    case 'Molecular':
      router.push({name:'Molecular'});
      break;
  }
};
const value = ref('Usage')
const options = [
  {
    value: 'Usage',
    label: 'Usage',
  },
  {
    value: 'SMILES',
    label: 'SMILES',
  },
];
const input = ref('');
const currentOption = ref('Usage');
const Select = (command) => {
  currentOption.value = command;
  if(command ==='SMILES'){
    document.querySelector('.el-input__inner').placeholder =  'CC(=O)OO';
  }
  else if(command ==='Usage'){
    document.querySelector('.el-input__inner').placeholder =  'Agrochemicals';
  }
};
const search = async () => {
  if(currentOption.value=='Usage'){
    let inputValue = input.value.toLowerCase();
    switch(inputValue){
      case 'medicines':
        router.push({ name: 'Medicines' });
        break;
      case 'medicine':
        router.push({ name: 'Medicines' });
        break;

      case 'colorants':
        router.push({ name: 'Colorants' });
        break;
      case 'colorant':
        router.push({ name: 'Colorants' });
        break;

      case 'cosmetics':
        router.push({ name: 'Cosmetics' });
        break;
      case 'cosmetic':
        router.push({ name: 'Cosmetics' });
        break;

      case 'additives':
        router.push({ name: 'Additives' });
        break;
      case 'additive':
        router.push({ name: 'Additives' });
        break;

      case 'surfactants':
        router.push({ name: 'Surfactants' });
        break;
      case 'surfactant':
        router.push({ name: 'Surfactants' });
        break;

      case 'agrochemicals':
        router.push({ name: 'Agrochemicals' });
        break;
      case 'agrochemical':
        router.push({ name: 'Agrochemicals' });
        break;

      default:
      { alert("Please input information you want to search！")}
    }
  } else if( currentOption.value==='SMILES'){
    if(input.value !== ''){
      console.log(input.value);
      axios.post("/api_greenchem/searchBySmiles", {smiles:input.value},{timeout: 5000})
          .then(Searchrespons =>{
            console.log(Searchrespons);
            if(Searchrespons.data.code == '500'){
              alert("Sorry, there is no such  information contained in the GreenChemDB at that time.");
            }else{
              router.push({name: 'SearchSmiles', query: {inputValue: input.value}});
            }
          })
    }
  }
};
function  renderChart() {
  var chartDom = document.getElementById('chartContainer');
  var myChart = echarts.init(chartDom);
  var option;

  option = {
    color: [
      '#37A2DA',
      '#32C5E9',
      '#67E0E3',
      '#9FE6B8',
      '#FFDB5C',
      '#ff9f7f',
      '#fb7293',
      '#E062AE',
      '#E690D1',
      '#e7bcf3',
      '#9d96f5',
      '#8378EA',
      '#96BFFF'
    ],
    series: {
      type: 'sankey',
      layout: 'none',
      emphasis: {
        focus: 'adjacency'
      },
      data: [
        { name: 'TDB', itemStyle: { color: '#37A2DA' } },
        { name: 'TOXRIC' },
        { name: 'CleanGrediants' },
        { name: 'Additives' },
        { name: 'Agrochemicals' },
        { name: 'Colorants' },
        { name: 'Comestics' },
        { name: 'Medicines' },
        { name: 'Surfactants' },
        { name: 'PPDB' },
        { name: 'DrugBank' },
        { name: 'PubChem' }
      ],
      links: [
        {
          source: 'TDB',
          target: 'Additives',
          value: 146,
          lineStyle: { color: '#37A2DA' }
        },
        {
          source: 'TDB',
          target: 'Agrochemicals',
          value: 363,
          lineStyle: { color: '#37A2DA' }
        },
        {
          source: 'TDB',
          target: 'Colorants',
          value: 4,
          lineStyle: { color: '#37A2DA' }
        },
        {
          source: 'TDB',
          target: 'Comestics',
          value: 1,
          lineStyle: { color: '#37A2DA' }
        },
        {
          source: 'TDB',
          target: 'Medicines',
          value: 123,
          lineStyle: { color: '#37A2DA' }
        },
        {
          source: 'TDB',
          target: 'Surfactants',
          value: 17,
          lineStyle: { color: '#37A2DA' }
        },
        {
          source: 'TOXRIC',
          target: 'Additives',
          value: 195,
          lineStyle: { color: '#9d96f5' }
        },
        {
          source: 'TOXRIC',
          target: 'Agrochemicals',
          value: 254,
          lineStyle: { color: '#9d96f5' }
        },
        {
          source: 'TOXRIC',
          target: 'Colorants',
          value: 0,
          lineStyle: { color: '#9d96f5' }
        },
        {
          source: 'TOXRIC',
          target: 'Comestics',
          value: 13,
          lineStyle: { color: '#9d96f5' }
        },
        {
          source: 'TOXRIC',
          target: 'Medicines',
          value: 707,
          lineStyle: { color: '#9d96f5' }
        },
        {
          source: 'TOXRIC',
          target: 'Surfactants',
          value: 35,
          lineStyle: { color: '#9d96f5' }
        },
        {
          source: 'CleanGrediants',
          target: 'Additives',
          value: 99,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'CleanGrediants',
          target: 'Agrochemicals',
          value: 9,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'CleanGrediants',
          target: 'Colorants',
          value: 4,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'CleanGrediants',
          target: 'Comestics',
          value: 13,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'CleanGrediants',
          target: 'Medicines',
          value: 47,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'CleanGrediants',
          target: 'Surfactants',
          value: 48,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'Additives',
          target: 'PPDB',
          value: 26,
          lineStyle: { color: '#FFDB5C' }
        },
        {
          source: 'Additives',
          target: 'DrugBank',
          value: 102,
          lineStyle: { color: '#FFDB5C' }
        },
        {
          source: 'Additives',
          target: 'PubChem',
          value: 228,
          lineStyle: { color: '#FFDB5C' }
        },
        {
          source: 'Agrochemicals',
          target: 'PPDB',
          value: 371,
          lineStyle: { color: '#fb7293' }
        },
        {
          source: 'Agrochemicals',
          target: 'DrugBank',
          value: 32,
          lineStyle: { color: '#fb7293' }
        },
        {
          source: 'Agrochemicals',
          target: 'PubChem',
          value: 309,
          lineStyle: { color: '#fb7293' }
        },
        {
          source: 'Colorants',
          target: 'PPDB',
          value: 0,
          lineStyle: { color: '#9FE6B8' }
        },
        {
          source: 'Colorants',
          target: 'DrugBank',
          value: 0,
          lineStyle: { color: '#9FE6B8' }
        },
        {
          source: 'Colorants',
          target: 'PubChem',
          value: 4,
          lineStyle: { color: '#9FE6B8' }
        },
        {
          source: 'Comestics',
          target: 'PPDB',
          value: 0,
          lineStyle: { color: '#9FE6B8' }
        },
        {
          source: 'Comestics',
          target: 'DrugBank',
          value: 2,
          lineStyle: { color: '#9FE6B8' }
        },
        {
          source: 'Comestics',
          target: 'PubChem',
          value: 13,
          lineStyle: { color: '#9FE6B8' }
        },
        {
          source: 'Medicines',
          target: 'PPDB',
          value: 32,
          lineStyle: { color: '#96BFFF' }
        },
        {
          source: 'Medicines',
          target: 'DrugBank',
          value: 726,
          lineStyle: { color: '#96BFFF' }
        },
        {
          source: 'Medicines',
          target: 'PubChem',
          value: 518,
          lineStyle: { color: '#96BFFF' }
        },
        {
          source: 'Surfactants',
          target: 'PPDB',
          value: 1,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'Surfactants',
          target: 'DrugBank',
          value: 15,
          lineStyle: { color: '#32C5E9' }
        },
        {
          source: 'Surfactants',
          target: 'PubChem',
          value: 41,
          lineStyle: { color: '#32C5E9' }
        }
      ]
    }
  };
  myChart.setOption(option);
}

function getEchart(){
  var chartDom = document.getElementById('chartContainer-right');
  var Chart = echarts.init(chartDom);
  var option;

// This example requires ECharts v5.5.0 or later
  option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: '10%',
      left: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['50%', '70%'],
        // adjust the start and end angle
        startAngle: 180,
        endAngle: 360,
        data: [
          { value: 13, name: 'Comestics' },
          { value: 4, name: 'Colorants' },
          { value: 48, name: 'Surfactants' },
          { value: 232, name: 'Additives' },
          { value: 371, name: 'Agrochemicals' },
          { value: 726, name: 'Medicines' }
        ]
      }
    ]
  };
  Chart.setOption(option);
}
onMounted(() => {
    renderChart();
    getEchart();
});

</script>

<template>
  <div class="header">
    <div class="item">
      <img src="../assets/images/logo.png" width="50" height="50">
      <div class="context"> GreenChemDB</div>
    </div>
    <div class="item">
      <div class="item1">
        <div class="nav-text">
          <router-link to="/home" class="nav-link">Home</router-link>
        </div>
      </div>
      <div class="item1">
        <div class="nav-text">
          <router-link to="/search" class="nav-link">Search</router-link>
        </div>
      </div>
      <div class="item1">
        <select class="select" @change="click" >
          <option value="Browse" selected hidden="hidden">Fingerprint</option>
          <option value="Usage">Usage</option>
          <option value="Toxicity">Toxicity</option>
          <option value="Molecular">Molecular</option>
        </select>
      </div>
      <div class="item1">
        <div class="nav-text">
          <router-link to="/download" class="nav-link">Download</router-link>
        </div>
      </div>
      <div class="item1">
        <div class="nav-text" >
          <router-link to="/help" class="nav-link">Help</router-link>
        </div>
      </div>
      <div class="item1">
        <div class="nav-text">
          <router-link to="/contact" class="nav-link">Contact</router-link>
        </div>
      </div>

    </div>
  </div>
  <div class="layer1">
    <div class="items">
      <div class="layer1-title">
        <div class="head-text">
          Unprecedented collecting various information about green chemicals
        </div>
      </div>
      <div class="input-el">
        <el-select class="el-context" v-model="value" size="large" style="width: 6vw;border: 2px solid rgb(85,175,149);margin-top: 4vw" @change="Select" >
          <el-option class="el-context" v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value"/>
        </el-select>
        <el-input v-model="input" style="width: 25vw; margin-top: 4vw;height: 2.35vw;font-size: 1.2vw;border: 2px solid rgb(85,175,149)" placeholder="Catalysts" />
        <el-button style="width:6vw;height: 2.35vw;margin-top: 4vw; font-size: 1.5vw; background-color: #42b983;border: 2px solid rgb(85,175,149)" type="primary" @click="search" >Search</el-button>
      </div>
      <div class="back-img">
        <img src="../assets/images/home1.png" style="align-items: center;height: 20vw;width: 50vw">
      </div>
    </div>
  </div>
  <div class="layer2">
    <div class="bottom">
      <div class="items">
        <div class="item">
          <div id="chartContainer" style="width: 30vw; height: 25vw;"></div>
        </div>
        <div class="item">
          <div class="layer2-title">
            Data source  and distribution
          </div>
          <p class="context" style="align-items: center">
            GreenChemDB is a an open source database, including 1193 safe chemicals and more than 35000 corresponding health risks data about various species,
            such as animals, plants and microorganisms, etc. More than 50% safe chemicals are used as medicines, annotated with abundant information extracted from literature, TOXRIC, PubChem and other sources.
          </p>
        </div>
        <div class="item">
          <div id="chartContainer-right" style="width: 25vw; height: 25vw;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="layer3">
    <div class="top">Structure analysis</div>
    <div class="bottom">
      <div class="items">
        <div class="item">
          <img src="../assets/photo/PMI.png">
          <p class="title">PMI analysis</p>
          <p class="context">
            Principal moments of inertia (PMI) analysis shows that the geometric shapes of most safe chemicals are linear or plain.
          </p>
        </div>
        <div class="item">
          <img src="../assets/photo/PBF.png">
          <p class="title">PBF analysis</p>
          <p class="context">
            Plane of Best Fit (PBF) analysis shows that most safe chemicals are 2D or 3D shape characterization.
          </p>
        </div>
        <div class="item">
          <img src="../assets/photo/LogP_MW.png">
          <p class="title">LogP-MW analysis</p>
          <p class="context">
            LogP-MW analysis shows that for most safe chemicals, their MW range from 50 to 500, MLogP values is -2.5 to 6.0.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="layer4">
    <div class="top">Structure analysis</div>
    <div class="bottom">
      <div class="items">
        <div class="item">
          <img src="../assets/photo/density_XLogP3.png">
          <p class="title">Density analysis</p>
          <p class="context">
            Density analysis shows that most safe chemicals tend to possess similar molecular characteristics,
            despite their different usage.
          </p>
        </div>
        <div class="item">
          <img src="../assets/photo/PCA.png">
          <p class="title">PCA analysis</p>
          <p class="context">
            Principal component analysis (PCA) shows that safe chemicals tend to possess similar molecular characteristics,
            when mapped to the principal component space.
          </p>
        </div>
        <div class="item">
          <img src="../assets/photo/Medicines_scatter.png">
          <p class="title">Complexity analysis</p>
          <p class="context">
            Complexity analysis shows that the medicines, additives are a little complex than agrochemicals,
            which might due to the purpose of decreasing production costs.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <p class="copy">This website is free and open to all users and there is no login requirement.
      <br>
      © Copyright 2023, State Key Laboratory of Green Pesticide, Guizhou University.
    </p>
  </div>
  <router-view/>
</template>



<style scoped>
.header{
  width: 100%;
  height: 20%;
  background-color: rgb(224,239,221);
  display: flex;
  flex-direction: row;
}
.header .item{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 7vw;
}
.header .item+ .item{
  margin-left: 39%;
}
.header .item .item1:nth-child(n+2){
  margin-left: 2vw;
}
.header .item .item1 .nav-text{
  font-size: 1.2vw;
  font-family: "Times New Roman", Microsoft YaHei-Bold;
  color: #5ba449;
}
.header .item .context{
  font-size: 1.5vw;
  color: rgb(93,171,120);
  font-weight: bold;
  font-family: "Times New Roman", Microsoft YaHei-Bold;
}
.select{
  background-color: rgb(224,239,221) ;
  border: none;
  font-size: 1.2vw;
  font-family: "Times New Roman", Microsoft YaHei-Bold;
  color: rgb(93,171,120);
}
.nav-link{
  text-decoration: none;
  color: #5ba449;
}
.layer1{
  width: 100%;
  height: 30vw;
  background-color: rgb(224,239,221);
  display: flex;
  flex-direction: column;
}
.layer1 .items{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layer1 .items .layer1-title{
  height: 3vw;
  width: 40vw;
  align-items: center;
}
.layer1 .items .layer1-title .head-text{
  margin-top: 2vw;
  text-align: center;
  font-size: 1.5vw;
  font-family: "Times New Roman", sans-serif;
  color:  #155b38;
}



.input-el{
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-select .el-input,.el-select-dropdown .el-select-dropdown_item{
  font-size: 1.5vw;
  text-align: center;
  font-family: "Times New Roman", sans-serif;
  font-weight: bold;
}

.layer2 .bottom {
  margin-top: 1vw;
  margin-left: 2vw;
}

.layer2 .bottom .items {
  display: flex;
  width: 100%;
  margin-left: 2vw;
  flex-direction: row;
}

.layer2 .bottom .items .item {
  width: 32vw;
  align-items: center;
}
.layer2 .bottom .items .item:nth-child(n+2) {
  margin-left: 2vw;
}
.layer2 .bottom .items .item .layer2-title {
  margin-top: 2vw;
  width: 25vw;
  /*width: 29.9vw;*/
  height: 5vw;
  font-family: "Times New Roman", sans-serif;
  font-weight: 700;
  color: #5ba449;
  line-height: 1.56vw;
  text-align: center; /* 文本居中 */
  font-size: 2vw; /* 设置字体大小，根据需要调整 */
}
.layer2 .bottom .items .item .context {
  width: 25vw;
  font-size: 1.2vw;
  font-weight:400;
  font-family: "Times New Roman", sans-serif;
  /*text-align: left;*/
  color: #5ba449;
  line-height: 1.5vw;
  display: flex;
  justify-items: center;
  justify-content: center;
  text-align: justify;
}

.layer3 {
  display: inline-block;
  width: 100vw;
  height: 28.3vw;
  background-color: #fff;
}

.layer3 .top {
  margin-top: 2vw;
  /*width: 29.9vw;*/
  height: 1.56vw;
  font-family: "Times New Roman", sans-serif;
  font-weight: 700;
  color: #5ba449;
  line-height: 1.56vw;
  text-align: center; /* 文本居中 */
  font-size: 2vw; /* 设置字体大小，根据需要调整 */
}

.layer3 .bottom {
  margin-top: 2vw;
  margin-left: 7.55vw;
}

.layer3 .bottom .items {
  display: flex;
  /*justify-items: center;*/
  /*align-items: center;*/
}

.layer3 .bottom .items .item {
  width: 20.83vw;
  margin-left: 3.10vw;
  margin-right: 3.0vw;
  border: 1.5px solid #5ba449;
  border-radius: 8px;
  height: 22vw;
}

.layer3 .bottom .items .item img {
  width: 20.83vw;
  height: 15.83vw;
  align-items: center;
}

.layer3 .bottom .items .item .title {
  width: 20.83vw;
  height: 1.09vw;
  font-size: 1.04vw;
  font-family: "Times New Roman", sans-serif;
  font-weight: 700;
  line-height: 1.04vw;
  color: #5ba449;
  text-align: center;
  background-color: rgb(224,239,221);
}

.layer3 .bottom .items .item .context {
  width: 20.83vw;
  height: 6.67vw;
  font-size: 0.85vw;
  font-family: "Times New Roman", sans-serif;
  font-weight: 500;
  /*text-align: left;*/
  color: #333333;
  line-height: 1.24vw;
  display: flex;
  justify-items: center;
  justify-content: center;
  text-align:center;
}

.layer4 {
  display: inline-block;
  width: 100vw;
  height: 31.3vw;
  background-color: #fff;
}

.layer4 .top {
  margin-top: 2vw;
  /*width: 29.9vw;*/
  height: 1.56vw;
  font-size: 2vw;
  font-family: "Times New Roman", sans-serif;
  font-weight: 700;
  color: #5ba449;
  line-height: 1.56vw;
  text-align: center;
}

.layer4 .bottom {
  margin-top: 2vw;
  margin-left: 7.55vw;
}

.layer4 .bottom .items {
  display: flex;
  /*justify-items: center;*/
  /*align-items: center;*/
  height: 27vw;
}

.layer4 .bottom .items .item {
  width: 20.83vw;
  margin-left: 3.10vw;
  margin-right: 3.20vw;
  border: 1.5px solid #5ba449;
  border-radius: 8px;
  height: 25vw;
}

.layer4 .bottom .items .item img {
  width: 20.83vw;
  height: 18vw;
}

.layer4 .bottom .items .item .title {
  width: 20.83vw;
  height: 1.09vw;
  font-size: 1.04vw;
  font-family: "Times New Roman", sans-serif;
  font-weight: 700;
  line-height: 1.04vw;
  color: #5ba449;
  text-align: center;
  background-color: rgb(224,239,221);
}

.layer4 .bottom .items .item .context {
  width: 20.83vw;
  height: 6.67vw;
  font-size: 0.85vw;
  font-family: "Times New Roman", sans-serif;
  font-weight: 500;
  /*text-align: left;*/
  color: #333333;
  line-height: 1.24vw;
  display: flex;
  justify-items: center;
  justify-content: center;
  text-align: center;
}

.footer{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:rgb(224,239,221);
}
.footer .copy{
  margin-top: 0.87vw;
  width: 44.79vw;
  height: 2.29vw;
  font-size: 0.83vw;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  color: #666666;
}
</style>

