import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/search',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView.vue')
  },
  {
    path: '/browse',
    name: 'browse',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrowseView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownloadView.vue'),
  },
  {
    path: '/browse/toxicityinfor',
    name: 'toxicityinformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrowseInToxicityformation.vue'),
    props: true,
  },
  {
    path: '/browse/usage',
    name: 'Usageinformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrowseUsage.vue'),
    props: true,
  },
  {
    path: '/browse/molecular',
    name: 'Molecular',
    component: () => import(/* webpackChunkName: "about" */ '../views/BrowseMolecular.vue'),
    props: true,
  },
  {
    path: '/Search/Key',
    name: 'ResponseSearchKey',
    component: () => import(/* webpackChunkName: "about" */ '../views/Response-searchKey.vue'),
    props: true,
  },
  {
    path: '/SearchByUsage',
    name: 'SearchByUsage',
    component: () => import(/* webpackChunkName: "about" */ '../views/Response-ByUsage.vue'),
    props: true,
  },
  {
    path: '/SearchSmiles',
    name: 'SearchSmiles',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchSmiles.vue'),
    props: true,
  },
  {
    path: '/Search/identity',
    name: 'SearchIdentity',
    component: () => import(/* webpackChunkName: "about" */ '../views/Response-identity.vue'),
    props: true,
  },
  {
    path: '/click/image',
    name: 'ClickData',
    component: () => import(/* webpackChunkName: "about" */ '../views/Response-ClickImg.vue'),
    props: true,
  },
  {
    path: '/click/identity',
    name: 'ClickIdentity',
    component: () => import(/* webpackChunkName: "about" */ '../views/Response-DataClick.vue'),
    props: true,
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "about" */ '../views/HelpView.vue'),
  },
  {
    path: '/help/search',
    name: 'searchHelp',
    component: () => import(/* webpackChunkName: "about" */ '../views/help/searchHelp.vue'),
    props: true,
  },
  {
    path: '/help/browse',
    name: 'browseHelp',
    component: () => import(/* webpackChunkName: "about" */ '../views/help/BrowseHelp.vue'),
    props: true,
  },
  {
    path: '/help/download',
    name: 'downloadHelp',
    component: () => import(/* webpackChunkName: "about" */ '../views/help/DownHelp.vue'),
    props: true,
  },
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/Usage/Colorants',
    name: 'Colorants',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usage-response/Usage-Colorants.vue'),
    props: true,
  },
  {
    path: '/Usage/Cosmetics',
    name: 'Cosmetics',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usage-response/Usage-Cosmetics.vue'),
    props: true,
  },
  {
    path: '/Usage/Additives',
    name: 'Additives',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usage-response/Usage-Additives.vue'),
    props: true,
  },
  {
    path: '/Usage/Surfactants',
    name: 'Surfactants',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usage-response/Usage-Surfactants.vue'),
    props: true,
  },
  {
    path: '/Usage/Agrochemicals',
    name: 'Agrochemicals',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usage-response/Usage-Agrochemicals.vue'),
    props: true,
  },
  {
    path: '/Usage/Medicines',
    name: 'Medicines',
    component: () => import(/* webpackChunkName: "about" */ '../views/Usage-response/Usage-Medicines.vue'),
    props: true,
  },
  {
    path: '/Toxicity/Animals',
    name: 'Animals',
    component: () => import(/* webpackChunkName: "about" */ '../views/Toxicity-response/ToxicityAnimals.vue'),
    props: true,
  },
  {
    path: '/Toxicity/Plants',
    name: 'Plants',
    component: () => import(/* webpackChunkName: "about" */ '../views/Toxicity-response/ToxicityPlants.vue'),
    props: true,
  },
  {
    path: '/Toxicity/Organism',
    name: 'Organism',
    component: () => import(/* webpackChunkName: "about" */ '../views/Toxicity-response/ToxicityOrganism.vue'),
    props: true,
  },
  {
    path: '/Molecular/FCFP4',
    name: 'FCFP4',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/FCFP4-response.vue'),
    props: true,
  },
  {
    path: '/Molecular/ECFP4',
    name: 'ECFP4',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/ECFP4-response.vue'),
    props: true,
  },
  {
    path: '/Molecular/ECFP6',
    name: 'ECFP6',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/ECFP6-response.vue'),
    props: true,
  },
  {
    path: '/Molecular/MACCS',
    name: 'MACCS',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/MACCS-response.vue'),
    props: true,
  },
  {
    path: '/Molecular/Estate',
    name: 'Estate',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/Estate-response.vue'),
    props: true,
  },
  {
    path: '/Molecular/RDkit',
    name: 'RDkit',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/RDkit-response.vue'),
    props: true,
  },
  {
    path: '/Molecular/Mol2vec',
    name: 'Mol2vec',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/Mol2vec-response.vue'),
    props: true,
  },
  {
    path: '/Molecular/PubChem',
    name: 'PubChem',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Molecular-response/PubChem-response.vue'),
    props: true,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  console.log('Navigating from', from.fullPath, 'to', to.fullPath);
  console.log('Route params:', to.params);
  next();
});
export default router
